import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReactMarkdown from 'react-markdown';

import { Card, CardActionArea, CardMedia, CardContent, CardActions, Button } from '@mui/material';

function PrivacyService() {

    const content = `
## 隐私政策

*最后更新日期：2025.02.19*

### 0. 基本信息

1. 应用名称: *XCampus 课程表*
2. 软件开发者: *蔡徐博*
3. 运营方: *南京炡云数字科技有限公司*

### 1. 引言

欢迎使用 **XCampus 课程表** ！我们非常重视您的隐私和数据安全。本隐私政策将帮助您了解我们如何收集、使用、存储和保护您的个人信息，以及您在使用 XCampus 应用时的权利。

使用 **XCampus 课程表**，代表您同意本隐私政策的内容。如果您对本隐私政策有任何疑问或建议，请通过 [客服邮箱(cxbsoft@zhengcloud.ltd)](mailto:cxbsoft@zhengcloud.ltd)联系我们。

### 2. 我们收集的信息

在您使用 **XCampus 课程表** 的过程中，我们可能会收集以下信息：

- 注册信息：包括用户名、手机号码、密码等。
- 课程表信息：您在使用账号同步功能时上传的课程表。
- 时间表信息：您在使用时间表同校分享功能时上传的时间表。
- 位置数据（可选）：在您使用天气课表且授权的情况下，获取您的地理位置，用于获取您当前位置的天气信息。
- 

### 3. 我们如何使用您的信息

我们将收集的信息用于以下目的：

- 提供核心功能，例如课程表同步服务；
- 个性化功能体验，例如课表共享和时间表共享功能；
- 安全保障：验证身份以防止未经授权的访问。

### 4. 数据共享与披露

我们承诺不会将您的个人信息出售或出租给任何第三方。我们仅在以下情况下共享信息：

- 得到您的明确同意：我们会在信息共享前获取您的授权。
- 法律要求：在法律、司法程序或政府要求的情况下。
- 服务提供商：我们可能将信息传递给协助提供功能的可信服务提供商（如数据存储服务商），但需遵守本隐私政策并确保信息安全。

### 5. 数据存储与安全

- 数据存储：您的数据将存储在加密的数据库中，并受到严格的访问控制。
- 数据加密：在传输和存储过程中，我们会对您的敏感信息进行加密处理(例如您的密码)。
- 数据保留：我们仅在您使用 XCampus 服务期间保留您的数据。若您注销账号，我们将删除您的所有数据。

### 6. 您的权利

- 访问权：您可以随时查看我们存储的个人信息。
- 修改权：您可以修改您的个人信息。
- 删除权：您可以申请删除您的账户及所有相关数据。
- 撤回同意：您可以随时撤回对数据收集和使用的同意（例如禁用位置权限）。

### 7. 第三方服务

**XCampus 课程表**使用了以下第三方服务来提供更好的功能：

- 阿里云（提供云服务和短信验证、号码认证）
- 彩云天气（提供天气信息服务）
- HMS/GMS (提供位置信息服务)
- 微信开放平台SDK
  - 服务类型: 微信登录、课程表图片生成分享、微信支付
  - 收集的用户信息: 用户的微信昵称(用于注册时快速填写昵称)、微信头像(用于注册时快速设置头像为微信头像)、OpenID(用于将微信用户的唯一ID和**XCampus 课程表**账户绑定)
- 阿里云号码认证SDK
  - 服务类型: 提供本机号码一键登录服务
  - 收集的用户信息: 设备型号、操作系统版本、设备标识符、网络状态

- 邮件服务器(如果您使用邮箱功能，则会使用第三方邮箱的SMTP/IMAP服务)

这些第三方服务可能会收集或处理您的信息。我们建议您查看相关服务的隐私政策以了解更多信息。

### 8. 未成年保护

**XCampus 课程表** 的主要用户群为大学生，我们不向未满 18 岁的未成年人收集或存储个人信息。如发现未成年人未经同意提供了个人信息，可以立刻联系我们进行删除或自行注销账号删除

### 9. 隐私政策更新

我们可能会不时更新本隐私政策。如有重大变更，我们会通过应用内通知或其他适当方式告知您，并在更新后征求您的同意。

### 10. 联系我们

如果您对本隐私政策有任何疑问或意见，请通过以下方式联系我们：

- 邮箱：[cxbsoft@zhengcloud.ltd](mailto:cxbsoft@zhengcloud.ltd)
- QQ: 3319066174



**最后**，感谢您选择 **XCampus 课程表** ！我们致力于为您提供简洁、高可用的课表服务。
    `

    return (
        <Box>
            <Typography variant="h5" component="div" gutterBottom>
                隐私政策
            </Typography>
            <ReactMarkdown children={content} />
            
        </Box>
    )
}

export default PrivacyService